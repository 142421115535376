import config from 'config';
import axios from 'axios';
import {
  GET_IPAGE_BY_ROUTE_URL,
  GET_IPAGE_USING_AUTH,
  IPages,
} from 'constants/ipage';

export function getAPIUrl() {
  return config.apiBaseUrl;
}

export const getIPages: any = async () => {
  const url = getAPIUrl() + IPages;
  const response = await axios.get(url);
  const { data } = response;
  return data;
};

export const getIPageByRouteUrl = async (
  routeUrl: string,
  previewLink: string | string[] | undefined,
) => {
  try {
    const queryParams = `?url=${routeUrl}&${
      previewLink ? `preview=${previewLink}` : ''
    }`;
    const url = getAPIUrl() + GET_IPAGE_BY_ROUTE_URL + queryParams;
    const response = await axios.get(url);
    const { data } = response;
    return data;
  } catch (error) {
    console.log({ error });
  }
};

export const getIPageByAuth = async (
  routeUrl: string,
  previewLink: string | string[] | undefined,
) => {
  try {
    const userInfo = localStorage.getItem('persist:husslup');
    if (userInfo) {
      const user = JSON.parse(userInfo);
      const { auth } = user;
      const authToken = JSON.parse(auth);
      const { token } = authToken;
      if (token) {
        const queryParams = `?url=${routeUrl}&${
          previewLink ? `preview=${previewLink}` : ''
        }`;
        const url = getAPIUrl() + GET_IPAGE_USING_AUTH + queryParams;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
          },
        });
        const { data } = response;
        return data;
      }
      throw new Error('Not Authorized');
    }
    throw new Error('Not Authorized');
  } catch (error) {
    console.log({ error });
  }
};
