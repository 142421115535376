import useAuth from 'commons/hooks/auth';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import ErrorPage from 'next/error';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const Layout = dynamic(() => import('components/Layout'), { ssr: false });
const Loader = dynamic(() => import('components/Loader'), { ssr: false });
export interface IPageType {
  id: number;
  routeUrl: string;
  sourceUrl: string;
  title: string;
  requiresLogin: boolean;
  isLive: boolean;
  isDeleted: boolean;
  previewLink?: string;
}

const IPage = ({ pageContext, loading, notFound }: any) => {
  const router = useRouter();
  const { query } = router;
  const { isLoggedIn } = useAuth();
  const [hideLayout, setHideLayout] = useState(true);

  useEffect(() => {
    if (!isLoggedIn) {
      if (pageContext && pageContext.requiresLogin)
        router.push(`/Login/EmailLogin`);
    }
  }, [isLoggedIn, pageContext]);

  useEffect(() => {
    if (query) {
      const { nochrome } = query;
      if (pageContext !== undefined) {
        if (nochrome === undefined) setHideLayout(false);
        else setHideLayout(true);
      }
    }
  }, [query, pageContext]);

  useEffect(() => {
    document.querySelector('body')?.classList.add('ipage-test');

    return () => {
      document.querySelector('body')?.classList.remove('ipage-test');
    };
  }, []);

  if (
    notFound !== undefined &&
    notFound === true &&
    pageContext === undefined &&
    !loading
  ) {
    return (
      <ErrorPage
        statusCode={pageContext?.status ?? 404}
        title={
          pageContext?.message
            ? pageContext.message
            : 'This page could not be found'
        }
      />
    );
  }

  if (pageContext !== undefined) {
    const { sourceUrl, title } = pageContext;

    return (
      <Layout
        authenticated={isLoggedIn}
        hideFooter={hideLayout}
        hideHeader={hideLayout}
      >
        <NextSeo
          title={title}
          description='TRANSFORMING HOLLYWOOD’S OLD CLIQUE WITH ONE CLICK'
          openGraph={{
            url: 'https://husslup.com',
            title,
            description: 'TRANSFORMING HOLLYWOOD’S OLD CLIQUE WITH ONE CLICK',
            images: [
              {
                url: 'https://husslup.com/logo.svg',
                width: 600,
                height: 600,
                alt: 'ipages-image',
              },
              {
                url: 'https://husslup.com/logo.svg',
                width: 400,
                height: 400,
                alt: 'ipages-image',
              },
            ],
          }}
        />
        <>
          {loading ? (
            <Loader />
          ) : (
            <iframe
              src={sourceUrl}
              loading='eager'
              className='itest-iframe'
              title={title}
            />
          )}
        </>
      </Layout>
    );
  }

  return <Loader></Loader>;
};

export default IPage;
